import { GroupMatchType, MatchdayGroupType } from '../types';

export const organizeGroupMatches = (matches: GroupMatchType[]): MatchdayGroupType[] => {
  const matchdayGroups: { [key: number]: MatchdayGroupType } = {};

  matches.forEach(match => {
    if (!matchdayGroups[match.matchday]) {
      matchdayGroups[match.matchday] = {
        matchday: match.matchday,
        kickbaseMatchday: match.kickbaseMatchday,
        name: `Spieltag ${match.matchday}`,
        matches: []
      };
    }
    matchdayGroups[match.matchday].matches.push(match);
  });

  const organizedMatches = Object.values(matchdayGroups).sort((a, b) => a.matchday - b.matchday);
  console.log('Organized matches:', organizedMatches);
  return organizedMatches;
};
