import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { getTournamentStructure } from './utils/getTournamentStructure';
import { PlayerType } from './types';
import { calculateGameDays } from './utils/gameUtils';

interface QualificationRulesInfoProps {
  playerCount: number;
  isOpen: boolean;
  anchorEl: HTMLElement | null;
  players: PlayerType[];
  groups: any[];
}

const QualificationRulesInfo: React.FC<QualificationRulesInfoProps> = ({ playerCount, isOpen, anchorEl, players, groups }) => {
  const [position, setPosition] = useState({ top: 0, right: 0 });
  const structure = getTournamentStructure(playerCount);

  useEffect(() => {
    if (isOpen && anchorEl) {
      const rect = anchorEl.getBoundingClientRect();
      setPosition({
        top: rect.bottom + window.scrollY,
        right: window.innerWidth - rect.right + window.scrollX
      });
    }
  }, [isOpen, anchorEl]);

  const getRulesText = () => {
    const { groupCount, playersPerGroup, qualifiersPerGroup, additionalQualifiers, knockoutParticipants } = structure;

    let text = `${groupCount} Gruppe${groupCount > 1 ? 'n' : ''} mit ${playersPerGroup.join(', ')} Spielern\n\n`;

    const gameDays = calculateGameDays(Math.max(...playersPerGroup));
    text += `Spieltage: ${gameDays.join('. ')}.\n\n`;

    text += `Qualifikation K.O.-Phase:\n`;
    text += `• ${qualifiersPerGroup === 1 ? 'Erstplatzierte' : qualifiersPerGroup === 2 ? 'Erst- und Zweitplatzierte' : 'Erst-, Zweit- und Drittplatzierte'} jeder Gruppe\n`;
    if (additionalQualifiers > 0) {
      text += `• ${additionalQualifiers} beste ${additionalQualifiers === 1 ? 'Drittplatzierte' : 'Drittplatzierten'}\n`;
    }
    text += `Gesamt: ${knockoutParticipants} Teilnehmer\n\n`;

    text += `Rangfolge bei Gleichstand:\n`;
    text += `1) Punkte 2) Tordifferenz 3) Erzielte Tore\n`;
    text += `4) Direkter Vergleich 5) Losentscheid\n\n`;

    text += `K.O.-Phase:\n`;
    text += `• ${knockoutParticipants === 16 ? 'Achtel-, ' : ''}${knockoutParticipants >= 8 ? 'Viertel-, ' : ''}Halb-, Finale\n`;
    text += `• Unentschieden: Höhere Gesamtpunkte\n\n`;

    // Add player rankings and group assignments
    text += `Spieler-Rangliste (basierend auf Vorsaison-Punkten):\n`;
    const sortedPlayers = [...players].sort((a, b) => b.previousSeasonPoints - a.previousSeasonPoints);
    sortedPlayers.forEach((player, index) => {
      text += `${index + 1}. ${player.name}: ${player.previousSeasonPoints} Punkte\n`;
    });

    return text;
  };

  const InfoBox = () => (
    <motion.div
      initial={{ opacity: 0, y: -10 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -10 }}
      transition={{ duration: 0.2 }}
      className="fixed bg-secondary rounded-lg shadow-lg p-3 w-96 max-w-[calc(100vw-1rem)] border border-accent text-text overflow-y-auto max-h-[80vh]"
      style={{
        top: position.top,
        right: position.right,
        zIndex: 9999 // Extremely high z-index
      }}
    >
      <h3 className="font-bold mb-1 text-base text-accent">Turnierregeln und Gruppenaufteilung</h3>
      <p className="text-xs leading-tight whitespace-pre-line">{getRulesText()}</p>
    </motion.div>
  );

  return isOpen ? createPortal(<InfoBox />, document.body) : null;
};

export default QualificationRulesInfo;

