import React, { useState, useEffect, useCallback } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import CupSystem from './CupSystem';
import Login from './Login';
import Header from './Header';
import './fonts.css';
import './App.css';
import { Analytics } from "@vercel/analytics/react"
import { SpeedInsights } from "@vercel/speed-insights/react";
import { PlayerType } from './types';
import ApiService from './api';

const App: React.FC = () => {
  const [selectedLeagueId, setSelectedLeagueId] = useState<string | null>(null);
  const [playerCount, setPlayerCount] = useState(0);
  const [players, setPlayers] = useState<PlayerType[]>([]);
  const [groups, setGroups] = useState<any[]>([]);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [leagues, setLeagues] = useState<any[]>([]);

  const fetchLeagues = useCallback(async () => {
    try {
      const leagueInfo = await ApiService.getLeagueUserInfo();
      console.log('Fetched leagues:', leagueInfo);
      if (leagueInfo && leagueInfo.leagues && leagueInfo.leagues.length > 0) {
        setLeagues(leagueInfo.leagues);
        if (!selectedLeagueId) {
          setSelectedLeagueId(leagueInfo.leagues[0].id);
        }
      } else {
        console.log('No leagues available');
        setLeagues([]);
      }
    } catch (error) {
      console.error('Error fetching leagues:', error);
      setLeagues([]);
    }
  }, [selectedLeagueId]);

  useEffect(() => {
    const initializeApp = async () => {
      const token = localStorage.getItem('token');
      if (token) {
        setIsLoggedIn(true);
        await fetchLeagues();
      }
      setIsLoading(false);
    };

    initializeApp();
  }, [fetchLeagues]);

  const handleLeagueChange = (leagueId: string) => {
    setSelectedLeagueId(leagueId);
  };

  const handlePlayerCountUpdate = (count: number) => {
    setPlayerCount(count);
  };

  const handlePlayersUpdate = (updatedPlayers: PlayerType[]) => {
    setPlayers(updatedPlayers);
  };

  const handleGroupsUpdate = (updatedGroups: any[]) => {
    setGroups(updatedGroups);
  };

  const handleLogout = () => {
    console.log('Logging out');
    setIsLoggedIn(false);
    localStorage.removeItem('token');
    setSelectedLeagueId(null);
    setLeagues([]);
    setPlayers([]);
    setGroups([]);
    setPlayerCount(0);
  };

  const handleLoginSuccess = () => {
    console.log('Login successful');
    setIsLoggedIn(true);
    fetchLeagues();
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Router>
      <div className="App bg-background min-h-screen">
        <Header 
          onLeagueChange={handleLeagueChange} 
          playerCount={playerCount}
          players={players}
          groups={groups}
          onLogout={handleLogout}
          isLoggedIn={isLoggedIn}
          leagues={leagues}
          fetchLeagues={fetchLeagues}
        />
        <main className="container mx-auto p-4">
          <Routes>
            <Route 
              path="/login" 
              element={
                isLoggedIn ? <Navigate to="/" replace /> : <Login onLoginSuccess={handleLoginSuccess} />
              } 
            />
            <Route 
              path="/" 
              element={
                isLoggedIn ? (
                  selectedLeagueId ? (
                    <CupSystem 
                      leagueId={selectedLeagueId} 
                      onPlayerCountUpdate={handlePlayerCountUpdate}
                      onPlayersUpdate={handlePlayersUpdate}
                      onGroupsUpdate={handleGroupsUpdate}
                    />
                  ) : (
                    <p className="text-center text-text">Please select a league to start.</p>
                  )
                ) : (
                  <Navigate to="/login" replace />
                )
              } 
            />
          </Routes>
        </main>
        <Analytics />
        <SpeedInsights />
      </div>
    </Router>
  );
};

export default App;