import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import ApiService from './api';
import logo from './logo.png';

export interface LoginProps {
  onLoginSuccess: () => void;
}

const Login: React.FC<LoginProps> = ({ onLoginSuccess }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const response = await ApiService.login(username, password);
      if (response && response.token) {
        localStorage.setItem('token', response.token);
        onLoginSuccess();
        navigate('/');
      } else {
        setError('Login failed. No token received.');
      }
    } catch (error: any) {
      console.error('Login error:', error);
      setError('Login failed. Please check your credentials.');
    }
  };

  const fadeInUp = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 }
  };

  const transition = { duration: 0.6 };

  const staggerChildren = {
    animate: {
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen w-full bg-background font-sans">
      <motion.div 
        className="w-full max-w-4xl flex flex-col items-center px-4"
        initial="initial"
        animate="animate"
        variants={staggerChildren}
      >
        <motion.div 
          className="w-full flex justify-center" 
          style={{ height: '33vh' }}
          variants={fadeInUp}
          transition={transition}
        >
          <motion.img 
            src={logo} 
            alt="App Logo" 
            className="h-full object-contain"
            initial={{ scale: 0.8, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ duration: 0.5 }}
          />
        </motion.div>
        <motion.div 
          className="w-full max-w-md bg-secondary rounded-lg shadow-md p-6 mb-1"
          variants={fadeInUp}
          transition={transition}
        >
          <h2 className="text-xl font-bold mb-4 text-center">
            Login
          </h2>
          <div className="mb-4 text-center">
            <p className="text-text mb-3">
              Sicherer Login über die Kickbase API. 
            </p>
            <p className="mb-4 text-center">
              Der Anmeldetoken wird nur lokal auf deinem Gerät gespeichert.
            </p>
            {error && <p className="text-red-500 text-sm mt-2 ">{error}</p>}
          </div>
          <motion.form 
            onSubmit={handleSubmit} 
            className="space-y-6"
            variants={staggerChildren}
          >
            <motion.div variants={fadeInUp} transition={transition}>
              <input
                type="text"
                placeholder="Kickbase Email"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                className="w-full px-4 py-2 rounded-lg bg-background text-text placeholder-gray-500 border border-accent focus:border-accent focus:outline-none"
              />
            </motion.div>
            <motion.div variants={fadeInUp} transition={transition}>
              <input
                type="password"
                placeholder="Kickbase Passwort"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full px-4 py-2 rounded-lg bg-background text-text placeholder-gray-500 border border-accent focus:border-accent focus:outline-none"
              />
            </motion.div>
            <motion.div className="text-center" variants={fadeInUp} transition={transition}>
              <button
                type="submit"
                className="w-full px-4 py-2 rounded-lg bg-button hover:bg-button-hover text-text font-semibold focus:outline-none focus:ring-2 focus:ring-accent focus:ring-opacity-50 transition duration-300"
              >
                Einloggen
              </button>
            </motion.div>
          </motion.form>
        </motion.div>
        
        <motion.div 
          className="w-full text-text"
          variants={staggerChildren}
        >
          <motion.p className="text-lg font-bold mb-6 text-center mt-24" variants={fadeInUp} transition={transition}>
            Dein Pokalerlebnis für Kickbase - Wo Legenden geboren werden!
          </motion.p>
          <motion.p className="mb-4 text-center" variants={fadeInUp} transition={transition}>
            Du kennst und liebst Kickbase, das spannende Managerspiel für die Bundesliga. Aber was wäre, wenn du deine eigene Pokal-Legende schreiben könntest? Genau das ermöglicht dir der Kickly Cup!
          </motion.p>
          <motion.p className="mb-4 text-center" variants={fadeInUp} transition={transition}>
            Erinnere dich an Borussia Dortmunds unglaublichen Lauf bis ins Champions-League-Finale 2024 oder an die zahllosen Überraschungssiege von Underdogs im DFB-Pokal. Mit dem Kickly Cup kannst du solche magischen Momente in deiner Kickbase-Liga erleben!
          </motion.p>
          <motion.p className="mb-4 text-center" variants={fadeInUp} transition={transition}>
            Unser System generiert automatisch Spieltage, organisiert Gruppen- und K.O.-Phasen und ermittelt den Cupsieger basierend auf deinen Kickbase-Leistungen. Vielleicht bist du der nächste Underdog, der alle überrascht und den Pokal in die Höhe stemmt?
          </motion.p>
          <motion.p className="mb-4 text-center" variants={fadeInUp} transition={transition}>
            Stell dir vor: Dein Team kämpft sich durch dramatische Viertelfinalduelle, übersteht ein nervenaufreibendes Elfmeterschießen im Halbfinale und triumphiert schließlich in einem epischen Finale. All das ist im Kickly Cup möglich - und das Beste daran: Es basiert auf deinen echten Kickbase-Punkten!
          </motion.p>
          <motion.p className="mb-4 text-center" variants={fadeInUp} transition={transition}>
            So bleibt jede Saison bis zum Schluss aufregend, egal wie dein Start in der Liga war. Erlebe packende Duelle, überraschende Wendungen und die Chance, deine eigene Pokal-Geschichte zu schreiben.
          </motion.p>
          <motion.p className="mb-6 text-center font-bold" variants={fadeInUp} transition={transition}>
            Sei dabei und werde zur Legende - Der Kickly Cup wartet auf dich!
          </motion.p>
        </motion.div>
        
        <motion.p 
          className="text-gray-400 text-2xl text-center mt-6"
          variants={fadeInUp}
          transition={transition}
        >
          Liegt dein Fokus auf der Liga? 
          <br />
          <a href="https://www.kickly.de" className="text-accent hover:text-accent-hover">www.kickly.de</a>
        </motion.p>
        <motion.p 
          className="text-gray-400 text-xs text-center mt-6"
          variants={fadeInUp}
          transition={transition}
        >
          Diese Seite steht in keinerlei Beziehung zu Kickbase.
        </motion.p>
      </motion.div>
    </div>
  );
};

export default Login;