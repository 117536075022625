import React from 'react';

const NavigationButtons: React.FC = () => {
  const scrollTo = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="sticky top-16 z-40 bg-background p-2 md:p-4 flex justify-center space-x-2 md:space-x-4 mb-4 md:mb-8">
      <button
        onClick={() => scrollTo('ergebnisse')}
        className="bg-secondary text-accent text-sm md:text-base font-bold py-1 px-2 md:py-2 md:px-4 rounded hover:bg-accent hover:text-secondary transition-colors"
      >
        Ergebnisse
      </button>
      <button
        onClick={() => scrollTo('gruppen')}
        className="bg-secondary text-accent text-sm md:text-base font-bold py-1 px-2 md:py-2 md:px-4 rounded hover:bg-accent hover:text-secondary transition-colors"
      >
        Gruppen
      </button>
      <button
        onClick={() => scrollTo('ko-phase')}
        className="bg-secondary text-accent text-sm md:text-base font-bold py-1 px-2 md:py-2 md:px-4 rounded hover:bg-accent hover:text-secondary transition-colors"
      >
        K.O.-Phase
      </button>
    </div>
  );
};

export default NavigationButtons;