import React, { useState, useEffect, useRef } from 'react';
import { motion, useInView, AnimatePresence } from 'framer-motion';
import { GroupMatchType, PlayerType } from './types';
import PlayerStats from './PlayerStats';
import { Tooltip } from 'react-tooltip';
import PlayerProfile from './PlayerProfile';

interface Player {
  name: string;
  profileUrl: string;
  cupPoints: number;
  matchesPlayed: number;
  wins: number;
  draws: number;
  losses: number;
  goalsFor: number;
  goalsAgainst: number;
  goalDifference: number;
  userId: string;
}

interface GroupProps {
  group: {
    name: string;
    players: Player[];
  };
  qualifiedCount: number;
  qualifiedThirds: number;
  totalPlayerCount: number;
  matchups: GroupMatchType[];
  qualifiedThirdPlacedTeams: string[];
}

const Group: React.FC<GroupProps> = ({ group, qualifiedCount, qualifiedThirds, totalPlayerCount, matchups, qualifiedThirdPlacedTeams }) => {
  const [showEliminated, setShowEliminated] = useState(false);
  const [selectedPlayer, setSelectedPlayer] = useState<PlayerType | null>(null);
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true, amount: 0.5 });

  useEffect(() => {
    if (isInView) {
      const timer = setTimeout(() => {
        setShowEliminated(true);
      }, 100);
      return () => clearTimeout(timer);
    }
  }, [isInView]);

  const sortedPlayers = [...group.players].sort((a, b) => {
    if (b.cupPoints !== a.cupPoints) return b.cupPoints - a.cupPoints;
    if (b.goalDifference !== a.goalDifference) return b.goalDifference - a.goalDifference;
    return b.goalsFor - a.goalsFor;
  });

  const isQualified = (player: Player, index: number) => {
    if (index < qualifiedCount) return true;
    if (index === 2 && qualifiedThirdPlacedTeams.includes(player.userId)) return true;
    return false;
  };

  const handlePlayerClick = (player: Player) => {
    setSelectedPlayer(player as PlayerType);
  };

  return (
    <motion.div 
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className="bg-secondary rounded-lg p-4 shadow-md"
      ref={ref}
    >
      <h3 className="text-xl font-bold mb-4">{group.name}</h3>
      <table className="w-full text-sm">
        <thead>
          <tr className="text-gray-400">
            <th className="text-left">Player</th>
            <th data-tooltip-id="stats-tooltip" data-tooltip-content="Matches Played">S</th>
            <th data-tooltip-id="stats-tooltip" data-tooltip-content="Wins">W</th>
            <th data-tooltip-id="stats-tooltip" data-tooltip-content="Draws">D</th>
            <th data-tooltip-id="stats-tooltip" data-tooltip-content="Losses">L</th>
            <th data-tooltip-id="stats-tooltip" data-tooltip-content="Goal Difference">TD</th>
            <th data-tooltip-id="stats-tooltip" data-tooltip-content="Cup Points">Pts</th>
          </tr>
        </thead>
        <tbody>
          {sortedPlayers.map((player, index) => {
            const qualified = isQualified(player, index);
            return (
              <motion.tr 
                key={player.userId}
                className={`border-t border-gray-700`}
                initial={{ opacity: 1, filter: 'grayscale(0%)' }}
                animate={{ 
                  opacity: showEliminated && !qualified ? 0.5 : 1,
                  filter: showEliminated && !qualified ? 'grayscale(100%)' : 'grayscale(0%)'
                }}
                transition={{ duration: 0.5, ease: "easeInOut" }}
              >
                <td 
                  className={`py-2 flex items-center ${showEliminated && !qualified ? 'text-gray-500' : 'text-text'} cursor-pointer`}
                  onClick={() => handlePlayerClick(player)}
                >
                  <div className="w-6 h-6 rounded-full mr-2 overflow-hidden bg-accent flex items-center justify-center">
                    {player.profileUrl ? (
                      <img
                        src={player.profileUrl}
                        alt={player.name}
                        className="w-full h-full object-cover"
                        onError={(e) => {
                          e.currentTarget.style.display = 'none';
                          (e.currentTarget.nextElementSibling as HTMLElement).style.display = 'flex';
                        }}
                      />
                    ) : (
                      <span className="text-background font-bold">{player.name.charAt(0)}</span>
                    )}
                  </div>
                  <span>{player.name}</span>
                </td>
                <td className={`text-center ${showEliminated && !qualified ? 'text-gray-500' : 'text-text'}`}>{player.matchesPlayed}</td>
                <td className={`text-center ${showEliminated && !qualified ? 'text-gray-500' : 'text-text'}`}>{player.wins}</td>
                <td className={`text-center ${showEliminated && !qualified ? 'text-gray-500' : 'text-text'}`}>{player.draws}</td>
                <td className={`text-center ${showEliminated && !qualified ? 'text-gray-500' : 'text-text'}`}>{player.losses}</td>
                <td className={`text-center ${showEliminated && !qualified ? 'text-gray-500' : 'text-text'}`}>
                  {player.goalDifference > 0 ? `+${player.goalDifference}` : player.goalDifference}
                </td>
                <td className={`text-center font-bold ${showEliminated && !qualified ? 'text-gray-500' : 'text-accent'}`}>
                  {player.cupPoints}
                </td>
              </motion.tr>
            );
          })}
        </tbody>
      </table>
      <Tooltip id="stats-tooltip" />
      <AnimatePresence>
        {selectedPlayer && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.3 }}
          >
            <PlayerProfile player={selectedPlayer} />
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
};

export default Group;