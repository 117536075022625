export const isWebAppMode = () => {
    // Check for iOS Safari
    const isIOSStandalone = 'standalone' in window.navigator && (window.navigator as any).standalone;
    
    // Check for other browsers using display-mode media query
    const isStandalone = window.matchMedia('(display-mode: standalone)').matches;
  
    // Check for fullscreen mode (some Android browsers)
    const isFullscreen = window.navigator.userAgent.includes('Android') && window.matchMedia('(display-mode: fullscreen)').matches;
  
    return isIOSStandalone || isStandalone || isFullscreen;
  };