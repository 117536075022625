import React, { useEffect, useRef } from 'react';
import { PlayerType } from './types';
import { Chart, registerables } from 'chart.js';

Chart.register(...registerables);

interface PlayerProfileProps {
  player: PlayerType;
}

const PlayerProfile: React.FC<PlayerProfileProps> = ({ player }) => {
  const chartRef = useRef<HTMLCanvasElement>(null);
  const chartInstance = useRef<Chart | null>(null);

  const currentSeasonStats = {
    points: player.currentSeasonPoints,
    cupPoints: player.cupPoints,
    matchesPlayed: player.matchesPlayed,
    wins: player.wins,
    draws: player.draws,
    losses: player.losses,
    averagePoints: player.matchesPlayed > 0 ? player.currentSeasonPoints / player.matchesPlayed : 0
  };

  const previousSeasonStats = {
    points: player.previousSeasonPoints,
    averagePoints: player.previousSeasonPoints / 34, // Assuming 34 matchdays in a season
    bestMatchday: Math.max(...(player.points || [0]))
  };

  // Use the player's historical data if available, or create a simple historical dataset
  const chartData = player.historicalData || [
    { season: '2022/2023', points: player.previousSeasonPoints, averagePoints: previousSeasonStats.averagePoints },
    { season: '2023/2024', points: player.currentSeasonPoints, averagePoints: currentSeasonStats.averagePoints }
  ];

  useEffect(() => {
    if (chartRef.current) {
      const ctx = chartRef.current.getContext('2d');
      
      if (ctx) {
        if (chartInstance.current) {
          chartInstance.current.destroy();
        }

        chartInstance.current = new Chart(ctx, {
          type: 'line',
          data: {
            labels: chartData.map(data => data.season),
            datasets: [
              {
                label: 'Total Points',
                data: chartData.map(data => data.points),
                borderColor: 'rgb(75, 192, 192)',
                tension: 0.1
              },
              {
                label: 'Average Points',
                data: chartData.map(data => data.averagePoints),
                borderColor: 'rgb(255, 99, 132)',
                tension: 0.1
              }
            ]
          },
          options: {
            responsive: true,
            scales: {
              x: {
                type: 'category',
                title: {
                  display: true,
                  text: 'Season',
                  font: {
                    size: 10
                  }
                },
                ticks: {
                  font: {
                    size: 8
                  }
                }
              },
              y: {
                title: {
                  display: true,
                  text: 'Points',
                  font: {
                    size: 10
                  }
                },
                ticks: {
                  font: {
                    size: 8
                  }
                }
              }
            },
            plugins: {
              legend: {
                labels: {
                  font: {
                    size: 10
                  }
                }
              }
            }
          }
        });
      }
    }

    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
    };
  }, [chartData]);

  return (
    <div className="bg-secondary rounded-lg p-2 shadow-md text-xs">
      <h2 className="text-sm font-bold mb-2">{player.name}</h2>
      <div className="grid grid-cols-2 gap-2 mb-2">
        <div>
          <h3 className="text-xs font-semibold mb-1">Current Season (2023/2024)</h3>
          <p>Total Points: {currentSeasonStats.points}</p>
          <p>Cup Points: {currentSeasonStats.cupPoints}</p>
          <p>Matches Played: {currentSeasonStats.matchesPlayed}</p>
          <p>Record: {currentSeasonStats.wins}W - {currentSeasonStats.draws}D - {currentSeasonStats.losses}L</p>
          <p>Average Points: {currentSeasonStats.averagePoints.toFixed(2)}</p>
        </div>
        <div>
          <h3 className="text-xs font-semibold mb-1">Previous Season (2022/2023)</h3>
          <p>Total Points: {previousSeasonStats.points}</p>
          <p>Average Points: {previousSeasonStats.averagePoints.toFixed(2)}</p>
          <p>Best Matchday: {previousSeasonStats.bestMatchday}</p>
        </div>
      </div>
      <div>
        <h3 className="text-xs font-semibold mb-1">Historical Performance</h3>
        <canvas ref={chartRef}></canvas>
      </div>
    </div>
  );
};

export default PlayerProfile;