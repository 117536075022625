import React, { useEffect } from 'react';
import { motion, useInView } from 'framer-motion';
import { MatchupType } from './types';

interface MatchupComponentProps {
  matchup: MatchupType;
  roundName: string;
  kickbaseMatchday?: number;
  showNames: boolean;
}

const MatchupComponent: React.FC<MatchupComponentProps> = ({ matchup, roundName, kickbaseMatchday, showNames }) => {
  const ref = React.useRef(null);
  const isInView = useInView(ref, { once: true, amount: 0.5 });

  const isByeMatch = !matchup.player2;
  const isUnplayedMatch = matchup.player1Points === '-' || matchup.player2Points === '-';
  const player1Points = isUnplayedMatch ? 0 : Number(matchup.player1Points);
  const player2Points = isUnplayedMatch ? 0 : Number(matchup.player2Points);
  const player1Won = !isByeMatch && !isUnplayedMatch && player1Points > player2Points;
  const isDraw = !isByeMatch && !isUnplayedMatch && player1Points === player2Points;

  useEffect(() => {
    console.log(`Matchup in ${roundName}:`);
    console.log(`Player 1: ${matchup.player1.name}, Points: ${matchup.player1Points}`);
    if (matchup.player2) {
      console.log(`Player 2: ${matchup.player2.name}, Points: ${matchup.player2Points}`);
    } else {
      console.log('Player 2: Bye');
    }
    console.log(`Kickbase Matchday: ${kickbaseMatchday}`);
    console.log(`Is Unplayed: ${isUnplayedMatch}`);
    console.log(`Is Bye: ${isByeMatch}`);
    console.log(`Player 1 Won: ${player1Won}`);
    console.log(`Is Draw: ${isDraw}`);
    console.log('---');
  }, [matchup, roundName, kickbaseMatchday, isUnplayedMatch, isByeMatch, player1Won, isDraw]);

  const containerVariants = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: { 
      opacity: 1, 
      scale: 1,
      transition: { 
        duration: 0.5,
        staggerChildren: 0.2
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: { 
        type: 'spring',
        stiffness: 100,
        damping: 10
      }
    }
  };

  const scoreVariants = {
    hidden: { opacity: 0, scale: 0 },
    visible: { 
      opacity: 1, 
      scale: 1,
      transition: { 
        type: 'spring',
        stiffness: 200,
        damping: 10
      }
    }
  };

  const playerVariants = {
    winner: { opacity: 1, filter: 'grayscale(0%)' },
    loser: { opacity: 0.6, filter: 'grayscale(100%)' },
    draw: { opacity: 1, filter: 'grayscale(0%)' },
  };

  const renderPlayerInfo = (player, isWinner) => (
    <motion.div 
      className="flex items-center"
      variants={playerVariants}
      animate={isByeMatch ? "winner" : isUnplayedMatch ? "draw" : isWinner ? "winner" : isDraw ? "draw" : "loser"}
      transition={{ duration: 0.5 }}
    >
      {showNames && (
        <div className="w-8 h-8 rounded-full mr-2 overflow-hidden">
          {player.profileUrl ? (
            <img
              src={player.profileUrl}
              alt={player.name}
              className="w-full h-full object-cover"
              onError={(e) => {
                e.currentTarget.style.display = 'none';
                (e.currentTarget.nextElementSibling as HTMLElement).style.display = 'flex';
              }}
            />
          ) : (
            <div className="w-full h-full placeholder-profile bg-gray-300 flex items-center justify-center text-gray-600 font-bold">
              {player.name.charAt(0)}
            </div>
          )}
        </div>
      )}
      <span className="font-medium">{showNames ? player.name : 'TBD'}</span>
    </motion.div>
  );

  return (
    <motion.div 
      ref={ref}
      className="bg-secondary rounded-lg p-4 shadow-md"
      variants={containerVariants}
      initial="hidden"
      animate={isInView ? "visible" : "hidden"}
    >
      <motion.div className="flex justify-between items-center" variants={itemVariants}>
        {renderPlayerInfo(matchup.player1, player1Won)}
        <motion.span 
          variants={scoreVariants}
          className={`font-bold ${
            isUnplayedMatch ? 'text-gray-500' :
            isByeMatch ? 'text-green-500' : 
            player1Won ? 'text-green-500' : 
            isDraw ? 'text-yellow-500' : 
            'text-gray-500'
          }`}
        >
          {isUnplayedMatch ? '-' : matchup.player1Points}
        </motion.span>
      </motion.div>
      {!isByeMatch ? (
        <motion.div className="flex justify-between items-center mt-2" variants={itemVariants}>
          {renderPlayerInfo(matchup.player2, !player1Won && !isDraw)}
          <motion.span 
            variants={scoreVariants}
            className={`font-bold ${
              isUnplayedMatch ? 'text-gray-500' :
              !player1Won && !isDraw ? 'text-green-500' : 
              isDraw ? 'text-yellow-500' : 
              'text-gray-500'
            }`}
          >
            {isUnplayedMatch ? '-' : matchup.player2Points}
          </motion.span>
        </motion.div>
      ) : (
        <motion.div className="flex justify-center items-center mt-2 text-gray-500" variants={itemVariants}>
          Bye
        </motion.div>
      )}
    </motion.div>
  );
};

export default MatchupComponent;