import axios from 'axios';
import { PlayerType } from './types';

const API_BASE_URL = 'https://api.kickbase.com';

const headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
};

const apiInstance = axios.create({
    baseURL: API_BASE_URL,
    headers,
});

const playerInfoCache: { [key: string]: any } = {};
const leagueStatsCache: { [key: string]: any } = {};
const playerPointsCache: { [key: string]: any } = {};
const marketPlayersCache: { [key: string]: any } = {};
let leaguesResponse: any = null;

apiInstance.interceptors.request.use((config) => {
    const token = localStorage.getItem('token');
    if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
}, (error) => {
    return Promise.reject(error);
});

export const logout = () => {
    localStorage.removeItem('token');
};

export const login = async (email: string, password: string) => {
    try {
        const response = await apiInstance.post('/user/login', {
            email,
            password,
            ext: false
        });

        if (response.status !== 200) {
            throw new Error(`Server responded with status: ${response.status}`);
        }

        if (response.data && response.data.token) {
            localStorage.setItem('token', response.data.token);
        }
        return response.data;
    } catch (error) {
        console.error('Login request failed:', error);
        throw error;
    }
};

export const getLeagueUserInfo = async () => {
    try {
        const response = await apiInstance.get('/leagues');
        return response.data;
    } catch (error: any) {
        if (error.response && (error.response.status === 403 || error.response.status === 401)) {
            logout();
            window.location.reload();
        }
        console.error('Get league user info request failed:', error);
        throw error;
    }
};

export const getUserInfo = async (leagueId: string) => {
    try {
        const response = await apiInstance.get(`/leagues/${leagueId}/me`);
        return response.data;
    } catch (error) {
        console.error('Get user info request failed:', error);
        throw error;
    }
};

export const getPlayerInfo = async (leagueId: string, playerId: string) => {
    const cacheKey = `${leagueId}_${playerId}`;
    if (playerInfoCache[cacheKey]) {
        return playerInfoCache[cacheKey];
    }

    try {
        const response = await apiInstance.get(`/leagues/${leagueId}/users/${playerId}/players`);
        playerInfoCache[cacheKey] = response.data;
        return response.data;
    } catch (error) {
        console.error('Get player info request failed:', error);
        throw error;
    }
};

export const getLeagueStats = async (leagueId: string) => {
    try {
        const response = await apiInstance.get(`/leagues/${leagueId}/stats`);
        const processedData = processLeagueStats(response.data);
        return processedData;
    } catch (error) {
        console.error('Error fetching league stats:', error);
        throw error;
    }
};

const processLeagueStats = (data: any) => {
    return {
        currentDay: data.currentDay,
        matchDays: data.matchDays,
        users: data.users.map((user: any) => {
            const userStats = data.matchDays[data.currentDay - 1].users.find((u: any) => u.userId === user.id);
            return {
                userId: user.id,
                name: user.name,
                profile: user.profile,
                totalPoints: userStats ? userStats.points : 0,
                dayPoints: userStats ? userStats.dayPoints : 0,
            };
        }),
    };
};

export const getMatchdayWinners = (stats: ReturnType<typeof processLeagueStats>, matchday: number) => {
    const matchdayStats = stats.matchDays.find((day: { day: number; }) => day.day === matchday);
    if (!matchdayStats) return [];

    // Sort users by dayPoints in descending order
    const sortedUsers = [...matchdayStats.users].sort((a, b) => b.dayPoints - a.dayPoints);
    
    // Return top 3 users
    return sortedUsers.slice(0, 3);
};

export const getUserProfile = async (leagueId: string, userId: string) => {
    try {
        const response = await apiInstance.get(`/leagues/${leagueId}/users/${userId}/profile`);
        return response.data;
    } catch (error) {
        console.error('Error fetching user profile:', error);
        throw error;
    }
};

export const getUserProfiles = async (leagueId: string, userIds: string[]) => {
    try {
        console.log(`Fetching profiles for league ${leagueId} and users:`, userIds);
        const profilesArray = await Promise.all(
            userIds.map(async userId => {
                try {
                    const response = await apiInstance.get(`/leagues/${leagueId}/users/${userId}/profile`);
                    console.log(`Profile for user ${userId}:`, response.data);
                    return { userId, profile: response.data };
                } catch (error) {
                    console.error(`Error fetching profile for user ${userId}:`, error);
                    return { userId, profile: null };
                }
            })
        );
        const profilesObject = profilesArray.reduce((acc, { userId, profile }) => {
            if (profile) {
                acc[userId] = profile;
            }
            return acc;
        }, {} as Record<string, any>);
        console.log('Profiles object:', profilesObject);
        return profilesObject;
    } catch (error) {
        console.error('Error fetching user profiles:', error);
        throw error;
    }
};

export const getPreviousSeasonPoints = (userProfile: any) => {
    if (!userProfile || !userProfile.seasons || userProfile.seasons.length < 2) {
        return 0;
    }

    // Sort seasons by seasonId in descending order
    const sortedSeasons = userProfile.seasons.sort((a, b) => 
        parseInt(b.seasonId) - parseInt(a.seasonId)
    );

    // Get the second season (previous season)
    const previousSeason = sortedSeasons[1];

    return previousSeason.points;
};

export const getCurrentSeasonPoints = (userProfile: any) => {
    if (!userProfile || !userProfile.seasons || userProfile.seasons.length === 0) {
        return 0;
    }

    // Get the current season (usually the last in the array)
    const currentSeason = userProfile.seasons[userProfile.seasons.length - 1];

    return currentSeason ? currentSeason.points : 0;
};

// Add other API calls as needed...

export const verifyToken = async (token: string) => {
    try {
        const response = await apiInstance.get('/user/verify', {
            headers: { Authorization: `Bearer ${token}` }
        });
        return response.data;
    } catch (error) {
        console.error('Token verification failed:', error);
        if (axios.isAxiosError(error) && error.response) {
            if (error.response.status === 404) {
                console.error('Token verification endpoint not found');
                // You might want to return true here if you don't have a verify endpoint
                // return true;
            } else if (error.response.status === 401 || error.response.status === 403) {
                console.error('Token is invalid or expired');
                return false;
            }
        }
        throw error;
    }
};

const ApiService = {
    login,
    logout,
    getLeagueUserInfo,
    getUserInfo,
    getPlayerInfo,
    getLeagueStats,  // This should now work correctly
    getMatchdayWinners,
    getUserProfile,
    getUserProfiles,
    verifyToken, // Add this line
    // Add other exported functions here...
};

export default ApiService;

// Add these new functions
export const cacheInitialGroups = (leagueId: string, groups: any[]) => {
    localStorage.setItem(`initialGroups_${leagueId}`, JSON.stringify(groups));
};

export const getInitialGroups = (leagueId: string): any[] | null => {
    const cachedGroups = localStorage.getItem(`initialGroups_${leagueId}`);
    return cachedGroups ? JSON.parse(cachedGroups) : null;
};

export const cachePlayerData = (leagueId: string, players: PlayerType[]) => {
    localStorage.setItem(`playerData_${leagueId}`, JSON.stringify(players));
};

export const getCachedPlayerData = (leagueId: string): PlayerType[] | null => {
    const cachedData = localStorage.getItem(`playerData_${leagueId}`);
    return cachedData ? JSON.parse(cachedData) : null;
};

export const clearCache = (leagueId: string) => {
    localStorage.removeItem(`initialGroups_${leagueId}`);
    localStorage.removeItem(`playerData_${leagueId}`);
};

