import React from 'react';
import { PlayerType } from './types';

interface WinnerProps {
  winner: PlayerType;
  finalScore: { winner: number; runnerUp: number };
}

const Winner: React.FC<WinnerProps> = ({ winner, finalScore }) => {
  return (
    <div className="text-center">
      <h2 className="text-2xl font-bold mb-4">Tournament Winner</h2>
      <p className="text-xl mb-2">{winner.name}</p>
      <p className="mb-4">Final Score: {finalScore.winner} - {finalScore.runnerUp}</p>
    </div>
  );
};

export default Winner;
