import React, { useState, useRef } from 'react';
import { QuestionMarkCircleIcon } from '@heroicons/react/20/solid';
import LeagueSelector from './LeagueSelector';
import QualificationRulesInfo from './QualificationRulesInfo';
import { isWebAppMode } from './utils/utils';
import { PlayerType } from './types';

interface HeaderProps {
  onLeagueChange: (leagueId: string) => void;
  playerCount: number;
  players: PlayerType[];
  groups: any[];
  onLogout: () => void;
  isLoggedIn: boolean;
  leagues: any[];
  fetchLeagues: () => Promise<void>;
}

const Header: React.FC<HeaderProps> = ({ onLeagueChange, playerCount, players, groups, onLogout, isLoggedIn, leagues, fetchLeagues }) => {
  const [showRules, setShowRules] = useState(false);
  const questionMarkRef = useRef<HTMLDivElement>(null);

  const toggleRules = (event: React.MouseEvent) => {
    event.stopPropagation();
    setShowRules(!showRules);
  };

  return (
    <header className={`text-text flex items-center justify-between h-16 px-2 md:px-4 relative ${isWebAppMode() ? 'pt-safe' : 'bg-background'}`}>
      <div className="flex items-center space-x-2 overflow-hidden">
        {isLoggedIn && (
          <div className="w-32 sm:w-40 md:w-48">
            <LeagueSelector onLeagueChange={onLeagueChange} leagues={leagues} fetchLeagues={fetchLeagues} />
          </div>
        )}
      </div>
      <div className="flex items-center space-x-1 sm:space-x-2">
        {isLoggedIn && (
          <>
            <QualificationRulesInfo 
              playerCount={playerCount}
              isOpen={showRules}
              anchorEl={questionMarkRef.current}
              players={players}
              groups={groups}
            />
            <button 
              onClick={() => window.location.href = "https://www.paypal.com/paypalme/kaiDetmers"} 
              className="bg-button hover:bg-button-hover text-text font-bold text-xs sm:text-sm py-1 px-1 sm:px-2 rounded-sm transition duration-200 ease-in"
            >
              Spenden
            </button>
            <button
              onClick={onLogout}
              className="bg-button hover:bg-button-hover text-text font-bold text-xs sm:text-sm py-1 px-1 sm:px-2 rounded-sm transition duration-300"
            >
              Logout
            </button>
            <div ref={questionMarkRef} className="relative">
              <QuestionMarkCircleIcon 
                className="h-5 w-5 sm:h-6 sm:w-6 text-accent cursor-pointer" 
                onClick={toggleRules}
              />
            </div>
          </>
        )}
        {!isLoggedIn && (
          <h1 className="text-xl sm:text-2xl font-bold">Kickbase Cup</h1>
        )}
      </div>
    </header>
  );
};

export default Header;