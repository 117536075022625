interface TournamentStructure {
  groupCount: number;
  playersPerGroup: number[];
  qualifiersPerGroup: number;
  additionalQualifiers: number;
  knockoutParticipants: number;
}

export const getTournamentStructure = (playerCount: number): TournamentStructure => {
  console.log("Getting tournament structure for", playerCount, "players");

  switch (playerCount) {
    case 0:
      return null
      ;
      case 1:
        return null;
        case 2:
          return null;
          case 3:
            return null;
            case 4:
              return null;
    case 5:
      return {
        groupCount: 1,
        playersPerGroup: [5],
        qualifiersPerGroup: 4,
        additionalQualifiers: 0,
        knockoutParticipants: 4
      };
    case 6:
      return {
        groupCount: 2,
        playersPerGroup: [3, 3],
        qualifiersPerGroup: 2,
        additionalQualifiers: 0,
        knockoutParticipants: 4
      };
    case 7:
      return {
        groupCount: 2,
        playersPerGroup: [4, 3],
        qualifiersPerGroup: 2,
        additionalQualifiers: 0,
        knockoutParticipants: 4
      };
    case 8:
      return {
        groupCount: 2,
        playersPerGroup: [4, 4],
        qualifiersPerGroup: 2,
        additionalQualifiers: 0,
        knockoutParticipants: 4
      };
    case 9:
      return {
        groupCount: 2,
        playersPerGroup: [5, 4],
        qualifiersPerGroup: 2,
        additionalQualifiers: 0,
        knockoutParticipants: 4
      };
    case 10:
      return {
        groupCount: 2,
        playersPerGroup: [5, 5],
        qualifiersPerGroup: 2,
        additionalQualifiers: 0,
        knockoutParticipants: 4
      };
    case 11:
      return {
        groupCount: 3,
        playersPerGroup: [4, 4, 3],
        qualifiersPerGroup: 2,
        additionalQualifiers: 2,
        knockoutParticipants: 8
      };
    case 12:
      return {
        groupCount: 3,
        playersPerGroup: [4, 4, 4],
        qualifiersPerGroup: 2,
        additionalQualifiers: 2,
        knockoutParticipants: 8
      };
    case 13:
      return {
        groupCount: 3,
        playersPerGroup: [5, 4, 4],
        qualifiersPerGroup: 2,
        additionalQualifiers: 2,
        knockoutParticipants: 8
      };
    case 14:
      return {
        groupCount: 3,
        playersPerGroup: [5, 5, 4],
        qualifiersPerGroup: 2,
        additionalQualifiers: 2,
        knockoutParticipants: 8
      };
    case 15:
      return {
        groupCount: 3,
        playersPerGroup: [5, 5, 5],
        qualifiersPerGroup: 2,
        additionalQualifiers: 2,
        knockoutParticipants: 8
      };
    case 16:
      return {
        groupCount: 4,
        playersPerGroup: [4, 4, 4, 4],
        qualifiersPerGroup: 2,
        additionalQualifiers: 0,
        knockoutParticipants: 8
      };
    case 17:
      return {
        groupCount: 4,
        playersPerGroup: [5, 4, 4, 4],
        qualifiersPerGroup: 2,
        additionalQualifiers: 0,
        knockoutParticipants: 8
      };
    case 18:
      return {
        groupCount: 3,
        playersPerGroup: [6, 6, 6],
        qualifiersPerGroup: 4,
        additionalQualifiers: 4,
        knockoutParticipants: 16
      };
    default:
      throw new Error(`Unsupported number of players: ${playerCount}`);
  }
};