import React, { useState, useEffect } from 'react';

interface LeagueSelectorProps {
    onLeagueChange: (leagueId: string) => void;
    leagues: any[];
    fetchLeagues: () => Promise<void>;
}

const LeagueSelector: React.FC<LeagueSelectorProps> = ({ onLeagueChange, leagues, fetchLeagues }) => {
    const [selectedLeague, setSelectedLeague] = useState<string>('');
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const initializeLeague = async () => {
            if (leagues.length === 0) {
                setIsLoading(true);
                await fetchLeagues();
                setIsLoading(false);
            } else {
                setIsLoading(false);
                if (!selectedLeague || !leagues.find(league => league.id === selectedLeague)) {
                    setSelectedLeague(leagues[0].id);
                    onLeagueChange(leagues[0].id);
                }
            }
        };

        initializeLeague();
    }, [leagues, selectedLeague, onLeagueChange, fetchLeagues]);

    const handleLeagueChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const leagueId = event.target.value;
        setSelectedLeague(leagueId);
        onLeagueChange(leagueId);
    };

    if (isLoading) {
        return <div className="text-xs sm:text-sm">Loading...</div>;
    }

    if (leagues.length === 0) {
        return <div className="text-xs sm:text-sm">No leagues available.</div>;
    }

    return (
        <select
            value={selectedLeague}
            onChange={handleLeagueChange}
            className="bg-secondary text-text p-1 sm:p-2 rounded-sm text-xs sm:text-sm w-full"
        >
            {leagues.map((league) => (
                <option key={league.id} value={league.id}>
                    {league.name}
                </option>
            ))}
        </select>
    );
};

export default LeagueSelector;