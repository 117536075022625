import React, { useState, useEffect, useMemo, useCallback } from 'react';
import ApiService, { getUserProfiles, getPreviousSeasonPoints, getCurrentSeasonPoints } from './api';
import Group from './Group';
import KnockoutStage from './KnockoutStage';
import { useNavigate } from 'react-router-dom';
import { createKnockoutStages as createKnockoutStagesUtil } from './utils/createKnockoutStages';
import { organizeGroupMatches } from './utils/matchUtils';
import { PlayerType, GroupMatchType, MatchupType, MatchdayGroupType, GroupMatch, Matchup, TournamentStructure } from './types';
import MatchupComponent from './MatchupComponent';
import Winner from './Winner';
import { motion, AnimatePresence } from 'framer-motion';
import NavigationButtons from './NavigationButtons';
import { getTournamentStructure } from './utils/getTournamentStructure';
import QualificationRulesInfo from './QualificationRulesInfo';
import { calculateGameDays } from './utils/gameUtils';

interface CupSystemProps {
  leagueId: string;
  onPlayerCountUpdate: (count: number) => void;
  onPlayersUpdate: (players: PlayerType[]) => void;
  onGroupsUpdate: (groups: any[]) => void;
}

const CupSystem: React.FC<CupSystemProps> = React.memo(({ leagueId, onPlayerCountUpdate, onPlayersUpdate, onGroupsUpdate }) => {
  const [players, setPlayers] = useState<PlayerType[]>([]);
  const [groups, setGroups] = useState<any[]>([]);
  const [groupMatches, setGroupMatches] = useState<GroupMatch[]>([]);
  const [organizedGroupMatches, setOrganizedGroupMatches] = useState<MatchdayGroupType[]>([]);
  const [knockoutStages, setKnockoutStages] = useState<MatchdayGroupType[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();
  const [winner, setWinner] = useState<PlayerType | null>(null);
  const [finalScore, setFinalScore] = useState<{ winner: number; runnerUp: number } | null>(null);
  const [tournamentStructure, setTournamentStructure] = useState<TournamentStructure | null>(null);
  const [isInitialized, setIsInitialized] = useState(false);
  const [qualifiedThirdPlacedTeams, setQualifiedThirdPlacedTeams] = useState<string[]>([]);
  const [userProfiles, setUserProfiles] = useState<Record<string, any>>({});
  const [playerRankings, setPlayerRankings] = useState<PlayerType[]>([]);
  const [isInfoOpen, setIsInfoOpen] = useState(false);
  const [infoAnchorEl, setInfoAnchorEl] = useState<HTMLElement | null>(null);
  const [currentKickbaseMatchday, setCurrentKickbaseMatchday] = useState<number>(0);

  const isGroupStageComplete = useMemo(() => {
    return groupMatches.length > 0 && groupMatches.every(match => match.played);
  }, [groupMatches]);

  const isFinalPlayed = useMemo(() => {
    if (knockoutStages.length === 0) return false;
    const finalStage = knockoutStages[knockoutStages.length - 1];
    return finalStage.matches.length > 0 && 
           finalStage.matches[0].played &&
           typeof finalStage.matches[0].player1Points === 'number' &&
           typeof finalStage.matches[0].player2Points === 'number';
  }, [knockoutStages]);

  useEffect(() => {
    if (isFinalPlayed && knockoutStages.length > 0) {
      const finalStage = knockoutStages[knockoutStages.length - 1];
      const finalMatch = finalStage.matches[0];
      if (typeof finalMatch.player1Points === 'number' && typeof finalMatch.player2Points === 'number') {
        const winnerPlayer = finalMatch.player1Points > finalMatch.player2Points ? finalMatch.player1 : finalMatch.player2;
        setWinner(winnerPlayer);
        setFinalScore({
          winner: Math.max(finalMatch.player1Points, finalMatch.player2Points),
          runnerUp: Math.min(finalMatch.player1Points, finalMatch.player2Points)
        });
      }
    }
  }, [isFinalPlayed, knockoutStages]);

  
  useEffect(() => {
    if (players.length > 0) {
      const structure = getTournamentStructure(players.length);
      setTournamentStructure(structure);
    }
  }, [players]);

  const fetchPlayersData = useCallback(async () => {
    console.log('fetchPlayersData started');
    try {
      setLoading(true);
      setError(null);

      const response = await ApiService.getLeagueStats(leagueId);
      console.log('League Stats Response:', JSON.stringify(response, null, 2));

      if (!response || !response.users || !response.matchDays) {
        throw new Error('Invalid response from server');
      }

      const userIds = response.users.map((user: any) => user.userId);
      console.log('User IDs:', userIds);

      const profiles = await getUserProfiles(leagueId, userIds);
      setUserProfiles(profiles);

      const currentDay = response.currentDay;
      setCurrentKickbaseMatchday(currentDay);

      const playersData: PlayerType[] = response.users.map((user: any) => {
        const userProfile = profiles[user.userId];

        // Get all points for the current season
        const points = response.matchDays.map((day: any) => {
          const userStats = day.users.find((u: any) => u.userId === user.userId);
          return userStats ? userStats.dayPoints : 0;
        });

        const previousSeasonPoints = userProfile ? getPreviousSeasonPoints(userProfile) : 0;

        return {
          userId: user.userId,
          name: user.name,
          profileUrl: user.profile,
          points: points,
          cupPoints: 0,
          matchesPlayed: 0,
          wins: 0,
          draws: 0,
          losses: 0,
          previousSeasonPoints: previousSeasonPoints,
          currentSeasonPoints: userProfile ? getCurrentSeasonPoints(userProfile) : 0,
        };
      });

      // Sort players by previous season points
      const sortedPlayers = [...playersData].sort((a, b) => b.previousSeasonPoints - a.previousSeasonPoints);

      setPlayers(playersData);
      onPlayersUpdate(playersData);
      onPlayerCountUpdate(playersData.length);
      setPlayerRankings(sortedPlayers);

      const structure = getTournamentStructure(playersData.length);
      setTournamentStructure(structure);

      if (structure) {
        const initialGroups = createGroups(sortedPlayers);

        setGroups(initialGroups);
        onGroupsUpdate(initialGroups);

        updateGroupMatches(initialGroups, response);
      }
    } catch (error: any) {
      console.error('Error in fetchPlayersData:', error);
      setError(error.message || 'An error occurred while fetching data');
      if (error.response && (error.response.status === 401 || error.response.status === 403)) {
        navigate('/login');
      }
    } finally {
      console.log('Setting loading to false');
      setLoading(false);
      setIsInitialized(true);
    }
  }, [leagueId, navigate]);

  useEffect(() => {
    fetchPlayersData();
  }, [fetchPlayersData]);

  const updateGroupMatches = (groups: any[], currentStats: any) => {
    const groupMatches = createGroupMatches(groups, currentStats.currentDay);
    setGroupMatches(groupMatches);
    
    const updatedGroups = calculateGroupStats(groups, groupMatches);
    setGroups(updatedGroups);

    if (tournamentStructure) {
      const qualifiedPlayers = getQualifiedPlayers(updatedGroups, tournamentStructure);
      const groupStageCount = Math.max(...groupMatches.map(m => m.matchday));
      const knockoutStages = createKnockoutStages(qualifiedPlayers, groupStageCount, currentStats.currentDay);
      setKnockoutStages(knockoutStages);
    }
  };

  const determineQualifiedThirdPlacedTeams = (groups: any[], structure: TournamentStructure) => {
    const thirdPlacedTeams = groups.map(group => {
      const sortedPlayers = [...group.players].sort((a, b) => 
        b.cupPoints - a.cupPoints || b.goalDifference - a.goalDifference || b.goalsFor - a.goalsFor
      );
      return sortedPlayers[2]; // Get the third-placed player
    });
  
    const sortedThirdPlaced = thirdPlacedTeams.sort((a, b) => 
      b.cupPoints - a.cupPoints || b.goalDifference - a.goalDifference || b.goalsFor - a.goalsFor
    );
  
    return sortedThirdPlaced.slice(0, structure.additionalQualifiers).map(player => player.userId);
  };
  
  // Update this useEffect to include the new logic
  useEffect(() => {
    if (groupMatches.length > 0) {
      const organized = organizeGroupMatches(groupMatches);
      setOrganizedGroupMatches(organized);
      const updatedGroups = calculateGroupStats(groups, groupMatches);
      setGroups(updatedGroups);
      
      if (tournamentStructure) {
        const qualifiedThirds = determineQualifiedThirdPlacedTeams(updatedGroups, tournamentStructure);
        setQualifiedThirdPlacedTeams(qualifiedThirds);
      }
    }
  }, [groupMatches, tournamentStructure]);
  const createGroups = (sortedPlayers: PlayerType[]) => {
    console.log('createGroups started with', sortedPlayers.length, 'players');
    
    const totalPlayers = sortedPlayers.length;
    const structure = getTournamentStructure(totalPlayers);
  
    const groups = [];
    let playerIndex = 0;
  
    for (let i = 0; i < structure.groupCount; i++) {
      const group = {
        name: `Gruppe ${String.fromCharCode(65 + i)}`,
        players: []
      };
  
      const playersInThisGroup = structure.playersPerGroup[i];
      for (let j = 0; j < playersInThisGroup && playerIndex < totalPlayers; j++) {
        group.players.push(sortedPlayers[playerIndex]);
        playerIndex++;
      }
      groups.push(group);
    }
  
    console.log('Groups created:', groups);
    return groups;
  };

  const createGroupMatches = (groups: any[], currentDay: number) => {
    const matches: GroupMatch[] = [];
    const maxGroupSize = Math.max(...groups.map(group => group.players.length));
    const groupKickbaseMatchdays = calculateGameDays(maxGroupSize);
    let groupMatchNumber = 1;
  
    groups.forEach((group) => {
      const players = group.players;
      const n = players.length;
      
      // If odd number of players, add a "bye" player
      if (n % 2 !== 0) {
        players.push({ name: "BYE", userId: "bye" });
      }
  
      const rounds = players.length - 1;
      const halfSize = players.length / 2;
  
  
      for (let round = 0; round < rounds; round++) {
        const kickbaseMatchday = groupKickbaseMatchdays[round];
        if (!kickbaseMatchday) break; // Stop if we run out of matchdays
  
        const isPlayed = kickbaseMatchday <= currentDay;
  
        for (let i = 0; i < halfSize; i++) {
          const player1 = players[i];
          const player2 = players[players.length - 1 - i];
  
          // Skip matches involving the "bye" player
          if (player1.userId !== "bye" && player2.userId !== "bye") {
  
            matches.push({
              groupName: group.name,
              player1,
              player2,
              matchday: round + 1,
              player1Points: isPlayed ? player1.points[kickbaseMatchday - 1] : null,
              player2Points: isPlayed ? player2.points[kickbaseMatchday - 1] : null,
              player1CupPoints: isPlayed ? (player1.points[kickbaseMatchday - 1] > player2.points[kickbaseMatchday - 1] ? 3 : player1.points[kickbaseMatchday - 1] === player2.points[kickbaseMatchday - 1] ? 1 : 0) : null,
              player2CupPoints: isPlayed ? (player2.points[kickbaseMatchday - 1] > player1.points[kickbaseMatchday - 1] ? 3 : player1.points[kickbaseMatchday - 1] === player2.points[kickbaseMatchday - 1] ? 1 : 0) : null,
              kickbaseMatchday,
              played: isPlayed,
              groupMatchNumber: groupMatchNumber++
            });
          } else {
            console.log(`${player1.userId === "bye" ? player2.name : player1.name} has a bye on matchday ${round + 1}`);
          }
        }
  
        // Rotate players for next round
        players.splice(1, 0, players.pop()!);
      }
  
      // Remove the "bye" player if it was added
      if (n % 2 !== 0) {
        players.pop();
      }
    });
  
    console.log('All created matches:', matches);
  
    // Debug: Count matches per player per matchday
    groups.forEach(group => {
      group.players.forEach(player => {
        const playerMatches = matches.filter(m => 
          m.player1.userId === player.userId || m.player2.userId === player.userId
        );
        groupKickbaseMatchdays.forEach((_, index) => {
          const playerMatchesOnDay = playerMatches.filter(m => m.matchday === index + 1);
        });
      });
    });
  
    return matches;
  };
  
  const calculateGroupStats = (groups: any[], matches: GroupMatch[]) => {
    return groups.map(group => ({
      ...group,
      players: group.players.map((player: PlayerType) => {
        const playerMatches = matches.filter(match => 
          (match.player1.userId === player.userId || match.player2.userId === player.userId) && match.played
        );
        const stats = playerMatches.reduce((acc, match) => {
          const isPlayer1 = match.player1.userId === player.userId;
          const points = isPlayer1 ? match.player1CupPoints : match.player2CupPoints;
          const goalsFor = isPlayer1 ? match.player1Points : match.player2Points;
          const goalsAgainst = isPlayer1 ? match.player2Points : match.player1Points;
          acc.cupPoints += points ?? 0;
          acc.matchesPlayed++;
          acc.goalsFor += goalsFor ?? 0;
          acc.goalsAgainst += goalsAgainst ?? 0;
          if (points === 3) acc.wins++;
          else if (points === 1) acc.draws++;
          else acc.losses++;
          return acc;
        }, { cupPoints: 0, matchesPlayed: 0, wins: 0, draws: 0, losses: 0, goalsFor: 0, goalsAgainst: 0 });
        return { 
          ...player, 
          ...stats,
          goalDifference: stats.goalsFor - stats.goalsAgainst
        };
      })
    }));
  };

  const getQualifiedPlayers = (groups: any[], structure: TournamentStructure) => {
    const qualifiedPlayers: PlayerType[] = [];
    
    groups.forEach((group, groupIndex) => {
      const sortedPlayers = [...group.players].sort((a, b) => 
        b.cupPoints - a.cupPoints || b.goalDifference - a.goalDifference || b.goalsFor - a.goalsFor
      );
      
      for (let i = 0; i < structure.qualifiersPerGroup; i++) {
        if (sortedPlayers[i]) {
          qualifiedPlayers.push({
            ...sortedPlayers[i],
            group: String.fromCharCode(65 + groupIndex), // Add group (A, B, C, etc.)
            groupPosition: i + 1 // Add position in group
          });
        }
      }
    });

    // Add best third-placed teams if needed
    if (structure.additionalQualifiers > 0) {
      const thirdPlacedTeams = groups.map((group, groupIndex) => {
        const sortedPlayers = [...group.players].sort((a, b) => 
          b.cupPoints - a.cupPoints || b.goalDifference - a.goalDifference || b.goalsFor - a.goalsFor
        );
        return {
          ...sortedPlayers[2],
          group: String.fromCharCode(65 + groupIndex),
          groupPosition: 3
        };
      }).sort((a, b) => 
        b.cupPoints - a.cupPoints || b.goalDifference - a.goalDifference || b.goalsFor - a.goalsFor
      );

      qualifiedPlayers.push(...thirdPlacedTeams.slice(0, structure.additionalQualifiers));
    }

    return qualifiedPlayers;
  };

  const calculateQualifiedThirds = (groups: { name: string; players: PlayerType[] }[]) => {
    const allThirds = groups.map(group => {
      const sortedPlayers = group.players.sort((a, b) => 
        b.cupPoints - a.cupPoints || (b.goalDifference || 0) - (a.goalDifference || 0)
      );
      return sortedPlayers[2] || null;
    }).filter(player => player !== null);

    const sortedThirds = allThirds.sort((a, b) => {
      if (!a || !b) return 0;
      return b.cupPoints - a.cupPoints || (b.goalDifference || 0) - (a.goalDifference || 0);
    });

    return sortedThirds.slice(0, 2).map(player => player?.name || 'Unknown');
  };

  useEffect(() => {
    if (groupMatches.length > 0) {
      const organized = organizeGroupMatches(groupMatches);
      console.log(`Organized group matches into ${organized.length} matchday groups`);
      setOrganizedGroupMatches(organized);
      const updatedGroups = calculateGroupStats(groups, groupMatches);
      setGroups(updatedGroups);
    }
  }, [groupMatches]);

  const createKnockoutStages = (qualifiedPlayers: PlayerType[], groupStageCount: number, currentDay: number) => {
    const stages = createKnockoutStagesUtil(qualifiedPlayers, groupStageCount, currentDay);

    // Ensure the final is on matchday 34
    if (stages.length > 0) {
      stages[stages.length - 1].kickbaseMatchday = 34;
    }

    return stages;
  };

  const getCurrentStandings = useCallback(() => {
    if (!groups.length) return [];
    
    const standings = groups.flatMap(group => 
      group.players.map(player => ({
        ...player,
        group: group.name
      }))
    ).sort((a, b) => {
      if (a.group !== b.group) return a.group.localeCompare(b.group);
      if (b.cupPoints !== a.cupPoints) return b.cupPoints - a.cupPoints;
      if (b.goalDifference !== a.goalDifference) return b.goalDifference - a.goalDifference;
      return b.goalsFor - a.goalsFor;
    });

    return standings;
  }, [groups]);

  const createInitialKnockoutStage = useCallback(() => {
    if (!tournamentStructure) return [];

    const qualifiedPlayers = getQualifiedPlayers(groups, tournamentStructure);
    
    // Sort qualified players to create proper pairings
    qualifiedPlayers.sort((a, b) => {
      if (a.group && b.group && a.group !== b.group) return a.group.localeCompare(b.group);
      return (a.groupPosition || 0) - (b.groupPosition || 0);
    });

    return createKnockoutStages(qualifiedPlayers, 0, 0); // This now returns all stages
  }, [tournamentStructure, groups]);

  useEffect(() => {
    if (groups.length > 0 && tournamentStructure) {
      const initialKnockoutStage = createInitialKnockoutStage();
      setKnockoutStages(initialKnockoutStage);
    }
  }, [groups, tournamentStructure, createInitialKnockoutStage]);

  if (loading) {
    return <div className="text-white text-center mt-8">Lade Turnierdaten...</div>;
  }

  if (error) {
    return <div className="text-red-500 text-center mt-8">Fehler beim Laden der Daten: {error}</div>;
  }

  if (!isInitialized || !tournamentStructure || players.length <= 1) {
    return (
      <div className="text-white text-center mt-8">
        <h2 className="text-2xl font-bold mb-4">Initialisiere Turnier...</h2>
        <p className="text-xl">
          Bitte warten Sie, während wir die Turnierdaten vorbereiten.
        </p>
      </div>
    );
  }

  return (
    <div className="min-h-screen text-text pd-4 md:p-8" id="ergebnisse">
      <NavigationButtons />

      <AnimatePresence mode="wait">
        {/* Group Stage */}
        <motion.div
          key="group-stage"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5 }}
        >
          <div>
            {organizedGroupMatches.map((matchday) => (
              <div key={matchday.matchday} className="mb-8">
                <motion.div 
                  className="bg-secondary rounded-lg p-4 mb-3 shadow-md relative overflow-hidden z-30"
                  initial={{ opacity: 0, y: -20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5 }}
                >
                  <h3 className="text-xl font-bold text-center text-accent relative z-10">
                    {matchday.matchday}. Spieltag
                    <br />
                    <span className="text-lg">({matchday.kickbaseMatchday}. Kickbase Spieltag)</span>
                  </h3>
                </motion.div>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                  {matchday.matches.map((match, index) => (
                    <MatchupComponent
                      key={index}
                      matchup={{
                        ...match,
                        player1Points: match.player1.points[matchday.kickbaseMatchday - 1] ?? '-',
                        player2Points: match.player2.points[matchday.kickbaseMatchday - 1] ?? '-'
                      }}
                      roundName={`Group Stage - Spieltag ${matchday.matchday}`}
                      kickbaseMatchday={matchday.kickbaseMatchday}
                      showNames={true}
                    />
                  ))}
                </div>
              </div>
            ))}
          </div>

          <div className="my-16 border-t-4 border-accent" id="gruppen"></div>

          <div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-8">
              {groups.map((group, index) => (
                <Group 
                  key={index} 
                  group={group} 
                  qualifiedCount={tournamentStructure?.qualifiersPerGroup || 0}
                  qualifiedThirds={tournamentStructure?.additionalQualifiers || 0}
                  totalPlayerCount={players.length}
                  matchups={groupMatches.filter(m => 
                    group.players.some(p => p.userId === m.player1.userId || p.userId === m.player2.userId)
                  )}
                  qualifiedThirdPlacedTeams={qualifiedThirdPlacedTeams}
                />
              ))}
            </div>
          </div>
        </motion.div>

        {/* Knockout Stage */}
        <motion.div
          key="knockout-stage"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5 }}
        >
          <div className="my-16 border-t-4 border-accent" id="ko-phase"></div>
          <div>
            {knockoutStages.map((stage, index) => (
              <div key={stage.name} className="mb-8">
                <motion.div 
                  className="bg-secondary rounded-lg p-4 mb-3 shadow-md relative overflow-hidden z-30"
                  initial={{ opacity: 0, y: -20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, delay: index * 0.1 }}
                >
                  <h3 className="text-xl font-bold text-center text-accent relative z-10">
                    {stage.name}
                    <br />
                    <span className="text-lg">
                      {isGroupStageComplete 
                        ? `(${stage.kickbaseMatchday}. Kickbase Spieltag)` 
                        : '(Vorläufige Paarungen)'}
                    </span>
                  </h3>
                </motion.div>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                  {stage.matches.map((match, matchIndex) => (
                    <MatchupComponent
                      key={matchIndex}
                      matchup={{
                        ...match,
                        player1Points: match.player1.points[stage.kickbaseMatchday - 1] ?? '-',
                        player2Points: match.player2 ? match.player2.points[stage.kickbaseMatchday - 1] ?? '-' : '-'
                      }}
                      roundName={stage.name}
                      kickbaseMatchday={stage.kickbaseMatchday}
                      showNames={index === 0}
                    />
                  ))}
                </div>
              </div>
            ))}
          </div>
        </motion.div>

        {isFinalPlayed && winner && finalScore && (
          <motion.div
            key="winner"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
          >
            <div className="mt-12">
              <Winner winner={winner} finalScore={finalScore} />
            </div>
          </motion.div>
        )}
      </AnimatePresence>

      <QualificationRulesInfo
        playerCount={players.length}
        isOpen={isInfoOpen}
        anchorEl={infoAnchorEl}
        players={players}
        groups={groups}
      />
    </div>
  );
});

export default CupSystem;