import { PlayerType, MatchupType, MatchdayGroupType } from '../types';

const getStageNameForParticipants = (participants: number): string => {
  switch (participants) {
    case 16: return 'Achtelfinale';
    case 8: return 'Viertelfinale';
    case 4: return 'Halbfinale';
    case 2: return 'Finale';
    default: return `Runde der letzten ${participants}`;
  }
};

const createPairings = (players: PlayerType[]): MatchupType[] => {
  const pairings: MatchupType[] = [];
  const halfLength = players.length / 2;

  for (let i = 0; i < halfLength; i++) {
    pairings.push({
      player1: players[i],
      player2: players[players.length - 1 - i],
      player1Points: '-',
      player2Points: '-',
      kickbaseMatchday: 0,
      played: false
    });
  }

  return pairings;
};

const getPlayerPoints = (player: PlayerType, kickbaseMatchday: number): number => {
  const currentSeason = player.seasons.reduce((latest, season) => 
    parseInt(season.seasonId) > parseInt(latest.seasonId) ? season : latest
  );

  const matchday = currentSeason.matchDays.find(md => md.day === kickbaseMatchday);
  return matchday ? matchday.pt : 0;
};

export const createKnockoutStages = (qualifiedPlayers: PlayerType[], groupStageCount: number, currentDay: number): MatchdayGroupType[] => {
  const stages: MatchdayGroupType[] = [];
  let remainingPlayers = [...qualifiedPlayers];
  let kickbaseMatchday = groupStageCount + 3; // Start 3 matchdays after group stage

  while (remainingPlayers.length > 1) {
    const stageName = getStageNameForParticipants(remainingPlayers.length);
    const matches = createPairings(remainingPlayers);

    matches.forEach(match => {
      match.kickbaseMatchday = kickbaseMatchday;
      match.played = kickbaseMatchday <= currentDay;
      if (match.played) {
        match.player1Points = getPlayerPoints(match.player1, kickbaseMatchday);
        if (match.player2) {
          match.player2Points = getPlayerPoints(match.player2, kickbaseMatchday);
        }
      }
    });

    stages.push({
      matchday: stages.length + 1,
      kickbaseMatchday,
      name: stageName,
      matches
    });

    // Determine winners for next stage
    remainingPlayers = matches.map(match => {
      if (match.played && match.player2) {
        const player1Points = typeof match.player1Points === 'number' ? match.player1Points : 0;
        const player2Points = typeof match.player2Points === 'number' ? match.player2Points : 0;
        return player1Points > player2Points ? match.player1 : match.player2;
      } else {
        // If the match hasn't been played or it's a bye, just return player1
        return match.player1;
      }
    });

    kickbaseMatchday += 3; // 3 matchdays between stages
  }

  return stages;
};